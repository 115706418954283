import * as React from "react";
import Page from "./src/components/page";
import "./src/styles/theme.module.css";
import "./src/styles/global.css";

const options = {
  "/coming-soon/": { customMain: true },
  "/": {customMain: true},
  "/about/": {customMain: true, banner: 0},
  "/partner/": {customMain: true, banner: 1},
  "/speaker/": {customMain: true, banner: 2},
  "/location/": {customMain: true, banner: 3},
    "/accessibilita/": {customMain: true},
      "/socials/": {customMain: true, banner: 3},
  "/404/": {customMain: true}
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <Page {...props} {...options[window.location.pathname]}>
      {element}
    </Page>
  );
};
