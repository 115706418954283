import * as React from "react";
import * as style from "../styles/navbar.module.css";
import logo from "../images/logo-white pisa.png";
import { Link } from "gatsby";
import { Menu, X } from "react-feather";
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

const buttons = {
  about: "about",
  speaker: "speaker",
  partner: "partner",
  contattaci: "socials",
};

const Navbar = ({ fields, className, ...props }) => {
  const [showButtonsPanel, setShowButtonsPanel] = useState(false);

  return (
    <nav {...props} className={`${className || ""}`}>
      <div className={style.logoContainer}>
        <Link to="/">
          <StaticImage
            src="../images/logo-white pisa.png"
            alt="Logo di TEDx Pisa"
            className={style.logo}
          />
        </Link>
      </div>
      <Menu
        className={`${style.menuButton} h-full cursor-pointer stroke-white py-4 lg:hidden`}
        onClick={() => setShowButtonsPanel(true)}
      />
      <Buttons
        visible={showButtonsPanel}
        className="w-max"
        onClose={() => setShowButtonsPanel(false)}
      />
    </nav>
  );
};

const Buttons = ({ visible, className, onClose, ...props }) => {
  return (
    <div
      className={`${visible ? "flex" : "hidden"} ${className || ""} fixed bottom-0 left-0 right-0 top-0 z-10 h-svh w-svw touch-none flex-col justify-between bg-black p-4 lg:static lg:flex lg:h-auto lg:flex-row lg:content-center lg:justify-end lg:p-0`}
      {...props}
    >
      <X
        className="justify-top h-10 w-10 cursor-pointer self-end stroke-white lg:hidden"
        onClick={onClose}
      />
      <div className="m-auto flex flex-col justify-center gap-6 text-center lg:m-0 lg:flex-row lg:content-center lg:justify-center lg:p-4">
        {Object.keys(buttons).map((text) => (
          <span className={`${style.button}`}>
            <Link
              to={
                buttons[text][0] === "/" ? buttons[text] : "/" + buttons[text]
              }
              activeClassName="border-ted-red border-solid border-b-4"
              onClick={onClose}
              key={buttons[text]}
              className="no-underline"
            >
              {text.toUpperCase()}
            </Link>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
