import * as React from "react";
import * as style from "../../styles/icon.module.css";

export const Icon = ({
  variants,
  variant,
  className,
  onPointerEnter,
  ...other
}) => {
  const Component = variants[variant];
  return (
    <Component
      className={`${style.icon} ${className || ""}`}
      onPointerEnter={(e) => {
        onPointerEnter?.(e);
      }}
      {...other}
    />
  );
};
