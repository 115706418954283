import * as React from "react";
import * as style from "../styles/page.module.css";
import "../styles/theme.module.css";
import Navbar from "./navbar.jsx";
import Footer from "./footer.jsx";

const Page = (props) => {
  const { children, excludeNavbar, excludeFooter, customMain } = props;
  console.log(children);
  return (
    <div className={style.container}>
      {!excludeNavbar && <Navbar />}
      {customMain ? (
        children
      ) : (
        <main className="flex h-full flex-col p-4 pt-5 lg:p-10">
          {children}
        </main>
      )}
      {!excludeFooter && <Footer />}
    </div>
  );
};

export default Page;

export const Head = () => <html lang="it" />;
