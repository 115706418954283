import * as React from "react";
import { Icon } from "./icon";
import Instagram from "../../images/icons/instagram_logo.svg";
import TikTok from "../../images/icons/tiktok_logo.svg";
import LinkedIn from "../../images/icons/linkedin_logo.svg";
import Facebook from "../../images/icons/facebook_logo.svg";
import YouTube from "../../images/icons/youtube_logo.svg";
import InstagramMonochrome from "../../images/icons/instagram_logo_monochrome.svg";
import TikTokMonochrome from "../../images/icons/tiktok_logo_monochrome.svg";
import LinkedInMonochrome from "../../images/icons/linkedin_logo_monochrome.svg";
import FacebookMonochrome from "../../images/icons/facebook_logo_monochrome.svg";
import YouTubeMonochrome from "../../images/icons/youtube_logo_monochrome.svg";

export const SocialIcon = ({
  social,
  kind = "color",
  onMouseEnter,
  onMouseLeave,
  url,
  className,
  ...other
}) => {
  const variants = {
    color: { Instagram, TikTok, LinkedIn, Facebook, YouTube },
    monochrome: {
      Instagram: InstagramMonochrome,
      TikTok: TikTokMonochrome,
      LinkedIn: LinkedInMonochrome,
      Facebook: FacebookMonochrome,
      Youtube: YouTubeMonochrome,
    },
  };

  const urls = {
    Instagram: "https://www.instagram.com/tedx.pisa",
    TikTok: "https://tiktok.com/@tedxpisa/",
    LinkedIn: "https://www.linkedin.com/company/tedxpisa/",
  };

  return (
    <a
      href={url || urls[social]}
      rel="noopener noreferrer"
      target="_blank"
      onMouseLeave={(e) => onMouseLeave?.(e)}
      {...other}
    >
      <Icon
        variants={variants[kind]}
        variant={social}
        alt={social + " logo"}
        title={social}
        className={className}
        onPointerEnter={(e) => {
          e.stopPropagation();
          onMouseEnter?.(e);
        }}
      />
    </a>
  );
};
