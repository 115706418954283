exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibilita-js": () => import("./../../../src/pages/accessibilita.js" /* webpackChunkName: "component---src-pages-accessibilita-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-socials-js": () => import("./../../../src/pages/socials.js" /* webpackChunkName: "component---src-pages-socials-js" */),
  "component---src-pages-sostenibilita-js": () => import("./../../../src/pages/sostenibilita.js" /* webpackChunkName: "component---src-pages-sostenibilita-js" */),
  "component---src-pages-speaker-2024-barbara-ilardo-js": () => import("./../../../src/pages/speaker/2024/barbara-ilardo.js" /* webpackChunkName: "component---src-pages-speaker-2024-barbara-ilardo-js" */),
  "component---src-pages-speaker-2024-beatrice-fraboni-js": () => import("./../../../src/pages/speaker/2024/beatrice-fraboni.js" /* webpackChunkName: "component---src-pages-speaker-2024-beatrice-fraboni-js" */),
  "component---src-pages-speaker-2024-maria-luisa-impastato-js": () => import("./../../../src/pages/speaker/2024/maria-luisa-impastato.js" /* webpackChunkName: "component---src-pages-speaker-2024-maria-luisa-impastato-js" */),
  "component---src-pages-speaker-2024-paolo-ferragina-js": () => import("./../../../src/pages/speaker/2024/paolo-ferragina.js" /* webpackChunkName: "component---src-pages-speaker-2024-paolo-ferragina-js" */),
  "component---src-pages-speaker-2024-ronke-oluwadare-js": () => import("./../../../src/pages/speaker/2024/ronke-oluwadare.js" /* webpackChunkName: "component---src-pages-speaker-2024-ronke-oluwadare-js" */),
  "component---src-pages-speaker-js": () => import("./../../../src/pages/speaker.js" /* webpackChunkName: "component---src-pages-speaker-js" */)
}

