import * as React from "react";
import * as style from "../styles/footer.module.css";
import { SocialIcon } from "./icons/social-icon";

const Footer = () => {
  return (
    <footer className="flex flex-col content-center justify-between p-2 text-center">
      <div className="mb-4 flex flex-col gap-1">
        <p style={{ "font-size": "17px" }} className="my-1 opacity-75">
          Seguici sui nostri social:
        </p>
        <div className="m-auto flex content-center gap-5">
          <SocialIcon social="Instagram" kind="color" />
          <SocialIcon social="TikTok" kind="color" />
          <SocialIcon social="LinkedIn" kind="color" />
        </div>
      </div>
      <p
        lang="en-US"
        style={{ "font-size": "12px", color: "#b3b3b3" }}
        className="mb-2 italic"
      >
        This work by <span property="cc:attributionName"> TEDxPisa</span> is
        licensed under{" "}
        <a
          href="https://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1"
          target="_blank"
          rel="license noopener noreferrer"
          style={{ display: "inline-block" }}
        >
          {" "}
          CC BY-SA 4.0
        </a>
      </p>
      <p lang="en-US" style={{ "font-size": "12px" }} className="italic">
        This independent TEDx event is operated
        <br />
        under license from TED.
      </p>
    </footer>
  );
};

export default Footer;
